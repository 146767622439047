<template>
  <section class="card">
    <link rel="stylesheet" type="text/css" href="/assets/css/relatorios.css">

    <data-header :handleTrocarVisao="handleTrocarVisao"
                 :filtros="filtros"
                 :itemSelecionado="itemSelecionado"
                 :actionButtons="actionButtons">
      <template slot="header">
        <b>Relatório de encerrados por período</b>
      </template>
      <template slot="subheader">
        <br />
        <small>Para extrair o relatório, clique em filtrar ao lado</small>
      </template>
    </data-header>
    <div class="row main-row">
      <filtro-component :filtros="filtros"
                        :params="params"
                        :handleConsultar="handleConsultar">
      </filtro-component>
      <div :class="'h-100 '+(filtros.visible ? 'col-lg-10 p0' : 'col-lg-12')">
        <div :class="'card-body card-tabela ' + (filtros.visible ? '' : 'p0')">
          <div class="h-100">
            <div class="table-responsive records">

              <table id="report_table" class="table table-bordered table-striped table-hover mb-0 table-sm">
                <thead>
                  <tr>
                    <th @click="handleOrdenar('Id')">
                      <span>
                        Despesa
                      </span>
                      <span class="pull-right">
                        <i v-if="params.order == 'Idf'"
                           :class="'el ' + (params.direction == 'desc' ? 'el-chevron-down' : 'el-chevron-up')">

                        </i>
                      </span>
                    </th>
                    <th @click="handleOrdenar('EstoqueId')">
                      <span>Cód. Veic.</span>
                      <span class="pull-right">
                        <i v-if="params.order == 'EstoqueId'"
                           :class="'el ' + (params.direction == 'desc' ? 'el-chevron-down' : 'el-chevron-up')">

                        </i>
                      </span>
                    </th>
                    <th>
                      <span>
                        Est
                      </span>

                    </th>
                    <th>
                      <span>
                        Data Venda
                      </span>
                    </th>

                    <th>
                      <span>Placa</span>
                    </th>
                    <th>
                      <span>Veículo</span>
                    </th>
                    <th>
                      <span>Localizacao</span>
                    </th>
                    <th>
                      <span>
                        Nota/Série
                      </span>
                    </th>
                    <th>
                      <span>
                        Tipo
                      </span>
                    </th>
                    <th>
                      <span>
                        Data NF
                      </span>
                    </th>
                    <th>
                      <span>
                        Valor
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(nf) in result.Records" :key="nf.RequisicaoId">
                    <td>{{nf.Id}}</td>
                    <td>{{nf.CodigoEstoqueDisplay}}</td>
                    <td>{{nf.Estoque}}</td>
                    <td>{{nf.DVendaDisplay}}</td>
                    <td>{{nf.Placa}}</td>
                    <td>{{nf.Veiculo}}</td>
                    <td>{{nf.Localizacao}}</td>
                    <td>{{nf.NotaSerie}}</td>
                    <td>{{nf.TipoRequisicao}}</td>
                    <td>{{nf.DataDocumentoDisplay}}</td>
                    <td>{{nf.ValorNF.toFixed(2).replace('.',',')}}</td>
                  </tr>
                  <tr v-if="result && result.Records">
                    <td colspan="5"><b>{{result.Records.length}} resultados</b></td>
                    <td colspan="6" class="total"><b>{{ (result.Records.length ? result.Records.map(c => c.ValorNF).reduce((partial_sum, a) => partial_sum + a) : 0).toFixed(2).replace('.',',') }}</b></td>
                  </tr>
                  <tr>
                    <td colspan="11" style="padding:50px">
                      <table class="table table-bordered table-striped table-hover mb-0 table-sm">
                        <thead>
                          <tr>
                            <th colspan="7" class="text-center">Resumo <span v-html="Object.keys(grouped).length"></span> chassis</th>
                          </tr>
                          <tr>
                            <th>Mês</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(periodo,index) in groupedPeriodo" :key="(index+1)">
                            <td>{{ (index +1) }}</td>
                            <td>{{ periodo.map(c => c.ValorNF).reduce((partial_sum, a) => partial_sum + a).toFixed(2).replace('.',',') }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section>
</template>

<script>
import consultar from '@/services/consultar.js';
import { resolveStatusRequisicaoClass, groupBy } from '@/services/helpers.js';


//Relatorios despesasemestoque
export default {

    components: {
        ...consultar.components,
    },
    data() {
        const that = this;
        return {
            ...consultar.data(),
            url: '/relatorios/despesasestoque',
            clone: '',
            obj: {},
            tipoRequisicaoFiltro: [],
            actionButtons: [
                {
                    text: '',
                    className: 'print padrao',
                    icon: 'fas fa-print',
                    //iconColor: 'green',
                    color: 'default',
                    visible: true,
                    title: 'Imprimir relatório',
                    callback: that.imprimir
                },
                {
                    text: '',
                    className: 'excel padrao',
                    icon: 'fas fa-file-excel',
                    iconColor: 'green',
                    color: 'default',
                    visible: true,
                    title: 'Exportar para excel',
                    callback: that.$root.exportCurrentTable
                    
                },
            ],
            itemSelecionado: [0],
            filtros:
            {
                data: [
                    {
                        id: 'DataCadastro',
                        title: 'Data Corte',
                        active: false,
                        type: 'date',
                        placeholder: 'Preencher igual'
                    },
                    {
                        id: 'tipoNota',
                        title: 'Tipo Nota',
                        active: false,
                        value: [],
                        sourceType: 'radio',
                        source: [
                            //{
                            //    id: 'StatusVeiculo',
                            //    title: 'Pendente',
                            //    value: 0
                            //},
                            {
                                id: 'tipoNota',
                                title: 'NF',
                                value: 'NF'
                            },
                            {
                                id: 'tipoNota',
                                title: 'OS',
                                value: 'OS'

                            },
                        ]
                },
                    {
                        id: 'Classificacao',
                        title: 'Classificação Requisição',
                        active: false,
                        source: [{
                            id: 'Classificacao',
                            title: 'Mecânica',
                            value: 0
                        },
                        {
                            id: 'Classificacao',
                            title: 'Estética',
                            value: 1
                        },
                        {
                            id: 'Classificacao',
                            title: 'Revisão',
                            value: 2
                        },
                        {
                            id: 'Classificacao',
                            title: 'Outros',
                            value: 3
                        },
                        ],
                        value: []

                    },
                ],
                visao: [
                    {
                        id: 0,
                        title: 'Todas requisições',
                        filtro: [
                            {
                                id: 'DataCadastro',
                                valuede: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0],
                                valueate: ''
                            }
                        ]
                    },
                ],
                visaoSelecionada: 0,
                visible: true
            },
            grouped: [],
            groupedPeriodo: []
            
        };
    },
    methods: {
        ...consultar.methods,
        changeCheckboxCallback: function () { this.itemSelecionado = [0] },
        callback: function (request) {
            let groupedP = this.groupedPeriodo;
            this.itemSelecionado = [0];
            this.grouped = groupBy(request.data.Records, 'EstoqueId');
            groupedP = groupBy(request.data.Records, 'Periodo');

            let ordered = {};
            Object.keys(groupedP).sort().forEach(function (key) {
                ordered[key] = groupedP[key];
            });
            groupedP = ordered;
            this.groupedPeriodo = groupedP;
        },
        resolveStatusClass: resolveStatusRequisicaoClass,
        somarNotas: function (results) {
            var total = 0;
            for (var i in results) {
                for (var j in results[i].Estoque.Requisicao) {
                    for (var h in results[i].Estoque.Requisicao[j].NotaFiscal) {
                        total += results[i].Estoque.Requisicao[j].NotaFiscal[h].Valor;
                    }
                }
            }

            return total.toFixed(2).replace('.', ',');
        }
    },
    mounted: function () {
        this.handleTrocarVisao(true);
    },
};
</script>

<style scoped>
  
td b {
    display: inline-block;
    text-decoration: underline
}

.total {
    text-align: right
}

</style>
